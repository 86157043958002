import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Usuario } from 'src/app/models/Usuario';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() logout = new EventEmitter();
  @Input() currentUser: Usuario = {};

  constructor() { }

  ngOnInit(): void {
  }

  logoutHeader(): void {
    this.logout.emit();
  }
}

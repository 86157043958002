<div class="section_general">
    <!-- BANNER NOSOTROS -->
    <div class="banner banner_nosotros">
        <h2>Artesanía Peruana de Diseño<br>Lo nuevo de lo nuestro</h2>
    </div>

    <div class="content-nosotros">
        <!-- NOSOTROS 1 -->
        <div class="nosotros1">
            <p class="mb-4">Piska, del vocablo quechua “cinco”, es la marca de Comercio Justo de BERROCAL, que ofrece artículos de decoración con diseños tradicionales y modernos, fusionando la cultura peruana y las diversas culturas del mundo, con técnica y mano de obra peruana, de excelente calidad. Nuestro objetivo es difundir la artesanía peruana en nuestro propio país, con el fin de que la artesanía de calidad y de diseño, esté al alcance de todos los peruanos.</p>
            <p class="text-dorado mb-5">La artesanía peruana es el legado cultural que nos pertenece, y es importante la continuidad de su producción y el reconocimiento y valoración por nuevas generaciones de peruanos.</p>
            <div>
                <img src="assets/images/about/img01.png" class="img-fluid">
            </div>
        </div>

        <!-- NOSOTROS 2 -->
        <div class="home4 nosotros2">
            <div class="row home4-1 mx-0">
                <div class="col-12 col-md-6 col-xl-5 home4-1-text">
                    <h2>MISIÓN</h2>
                    <p class="home-text-p">Difundir la artesanía peruana y su legado cultural en todas las generaciones de peruanos, promoviendo su desarrollo e innovación a través de la generación de experiencias únicas en los usuarios de nuestros productos y servicios.</p>
                </div>
                <div class="col-12 col-md-6 col-xl-7 home4-1-img px-3">
                    <div style="background-image: url(assets/images/about/img02.jpg);"></div>
                </div>
            </div>
            <div class="row home4-1 mx-0">
                <div class="col-12 col-md-6 col-xl-7 home4-1-img mb-0 px-3 order-1 order-md-0">
                    <div style="background-image: url(assets/images/about/img03.jpg);"></div>
                </div>
                <div class="col-12 col-md-6 col-xl-5 home4-1-text mb-md-0 order-0 order-md-1">
                    <h2>VISIÓN</h2>
                    <p class="home-text-p">Ser líderes en la comercialización de artesanía peruana, siendo reconocidos por promover la cultura del Perú.</p>
                </div>
            </div>
        </div>

        <!-- MARCAS -->
        <div class="px-3">
            <app-marcas-slider></app-marcas-slider>
        </div>

        <!-- NOSOTROS 3 -->
        <div class="nosotros3">
            <div class="row justify-content-end align-items-center pb-4 pb-xl-5">
                <div class="col-12 col-md mb-3 mb-md-0">
                    <h2>Conoce más de nuestros artesanos</h2>
                </div>
                <div class="col-12 col-md-auto">
                    <a mat-raised-button class="btn-dorado-p">VER MÁS</a>
                </div>
            </div>
            <div class="pt-4">
                <iframe width="100%" class="video-nosotros" src="https://www.youtube.com/embed/QWk6HfHl3rM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>
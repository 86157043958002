<div class="pt-5">
    <div class="col-12 col-md-5 mx-auto">
        <div class="box">
            <h2 class="">Genera código de validación</h2>
            <mat-card class="example-card mx-0">
                <mat-card-content>
                    <div class="p-2">
                        <form [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Ingrese su usuario</mat-label>
                                    <span matPrefix>
                                        <mat-icon class="custom-icon-input">person</mat-icon> &nbsp;
                                    </span>
                                    <input type="email" matInput formControlName="userName" required>
                                </mat-form-field>
                                <p class="text-success">Usuario correcto. Le hemos enviado a su correo el código de validación.</p>
                            </div>
                            <div class="col-12 col-md-8 mx-auto mt-4 mb-2">
                                <button type="submit" mat-raised-button color="primary" class="w-100">Validar Usuario</button>
                            </div>
                        </form>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompraService {

  constructor(private http: HttpClient) { }

  getInfoOrden(params: any){
    return this.http.post<any>(`${environment.apiURL}/web/catalogue/getData/Order`, params);
  }

  sendOrden(params: any){
    return this.http.post<any>(`${environment.apiURL}/mail/send/order`, params);
  }

  enviarFormulario(params: any){
    return this.http.post<any>(`${environment.apiURL}/mail/contactus`, params);
  }
}

<h1 mat-dialog-title class="titleModal">Rango de Precios</h1>
<div mat-dialog-content>
    <table class="table table-bordered bg-white">
        <thead class="table-light">
            <tr>
                <th scope="col">Cantidad Mínima</th>
                <th scope="col">Cantidad Máxima</th>
                <th scope="col">Precio</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let precio of listPrecios">
                <td>{{precio.minQuantity}}</td>
                <td>{{precio.maxQuantity}}</td>
                <td>S/ {{precio.amount | number: '1.2'}}</td>
            </tr>
        </tbody>
    </table>
</div>
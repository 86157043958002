import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Producto } from 'src/app/models/Producto';
import { ProductIntranetService } from 'src/app/services/intranet/product-intranet.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() labelHeader: string = "";
  @Input() data: Producto = {};
  @Output() stateProducto = new EventEmitter();
  loading: boolean = false;

  constructor(private router: Router, private productService: ProductIntranetService) { }

  ngOnInit(): void {
  }

  goToPage(): void {
    if(this.data.productsStateAdd == 2){
      this.router.navigate(['/admin/product/add/step3/'+this.data.idproducts]);
    }else if(this.data.productsStateAdd == 1){
      this.router.navigate(['/admin/product/add/step2/'+this.data.idproducts]);
    }
  }

  desactivarProducto(idProducto: number): void {
    Swal.fire({
      title: '¿Está seguro?',
      text: "Se archivará el producto.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#BE8A3E',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, archivar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;
        const request = {
          id: idProducto
          ,state: '0'
        };
        this.productService.changeStateProduct(request).subscribe(response => {
          Swal.fire({
            text: 'Se archivó el producto.',
            icon: 'success'
          });
          this.loading = false;
          this.stateProducto.emit();
        }, error => {
          console.log(error);
          this.loading = false;
          Swal.fire({
            text: 'Hubo un error al archivar el producto.',
            icon: 'error'
          });
        });
      }
    });
  }
}

<div class="section_general">
    <!-- BANNER PREGUNTAS -->
    <div class="banner banner_preguntas">
        <h2>Preguntas Frecuentes</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-8 mx-auto preguntas1">
        <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel class="accordion-question mb-3 shadow-none">
                <mat-expansion-panel-header>
                    <mat-panel-title class="align-items-center">
                        <img src="assets/images/questions/icono.png" alt="" class="img-fluid me-2">
                        <span>¿Como comprar en Piska?</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="p-3">
                    <p class="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum!</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="accordion-question mb-3 shadow-none">
                <mat-expansion-panel-header>
                    <mat-panel-title class="align-items-center">
                        <img src="assets/images/questions/icono.png" alt="" class="img-fluid me-2">
                        <span>¿Aquí irá la pregunta número 2?</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-3">
                    <p class="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum!</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="accordion-question mb-3 shadow-none">
                <mat-expansion-panel-header>
                    <mat-panel-title class="align-items-center">
                        <img src="assets/images/questions/icono.png" alt="" class="img-fluid me-2">
                        <span>¿Aquí irá la pregunta número 3?</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-3">
                    <p class="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum!</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="accordion-question mb-3 shadow-none">
                <mat-expansion-panel-header>
                    <mat-panel-title class="align-items-center">
                        <img src="assets/images/questions/icono.png" alt="" class="img-fluid me-2">
                        <span>¿Aquí irá la pregunta número 4?</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-3">
                    <p class="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum!</p>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel class="accordion-question mb-3 shadow-none">
                <mat-expansion-panel-header>
                    <mat-panel-title class="align-items-center">
                        <img src="assets/images/questions/icono.png" alt="" class="img-fluid me-2">
                        <span>¿Aquí irá la pregunta número 5?</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="p-3">
                    <p class="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illum nihil ut minima at ducimus unde tenetur, quasi corrupti deleniti quis fugit numquam, nemo quidem aspernatur facere pariatur similique! At, eum!</p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
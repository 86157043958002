<div class="pt-5">
    <div class="col-12 col-md-5 mx-auto">
        <div class="box">
            <h2 class="">Genera nueva contraseña</h2>
            <mat-card class="example-card mx-0">
                <mat-card-content>
                    <div class="p-2">
                        <form [formGroup]="recoverForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Ingresa el código</mat-label>
                                    <input type="text" matInput formControlName="codeRecover" required>
                                </mat-form-field>
                                <p class="text-success">Código correcto.</p>
                            </div>
                            <div class="col-12 col-md-8 mx-auto mt-4 mb-2">
                                <button type="submit" mat-raised-button color="primary" class="w-100">Validar código</button>
                            </div>
                        </form>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- NEW PASSWORD -->
            <mat-card class="example-card mx-0">
                <mat-card-content>
                    <div class="p-2">
                        <form [formGroup]="passwordForm" (ngSubmit)="onSubmitPassword()">
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Ingresa nueva contraseña</mat-label>
                                    <input type="password"
                                    matInput
                                    [(ngModel)]="password1"
                                    formControlName="newPassword"
                                    required>
                                </mat-form-field>
                            </div>
                            <div class="form-group">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Repita su nueva contraseña</mat-label>
                                    <input type="password"
                                    matInput
                                    [(ngModel)]="password2"
                                    formControlName="newPasswordReply"
                                    required>
                                </mat-form-field>
                            </div>
                            <p class="text-success">Contraseñas válidas.</p>
                            <div class="col-12 col-md-8 mx-auto mt-4 mb-2">
                                <button type="submit" mat-raised-button color="primary" class="w-100">Guardar</button>
                            </div>
                        </form>
                    </div>
                </mat-card-content>
            </mat-card>

            <!-- SUCCESS CHANGE -->
            <mat-card class="example-card mx-0">
                <div class="p-5">
                    <h2 class="title-success">Cambio exitoso</h2>
                    <p class="text-center mb-4">Estamos ingresando al sistema. Recuerde que a partir de ahora<br>puede ingresar con su nueva contraseña.</p>
                    <p class="text-center mb-0">Logueando...</p>
                </div>
            </mat-card>
        </div>
    </div>
</div>
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { DemoMaterialModule } from "../demo-material-module";
import { SharedModule } from "../shared/shared.module";
import { PortalRoutes } from "./portal-web.routing";
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { QuestionsComponent } from './questions/questions.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ContactComponent } from './contact/contact.component';
import { DeliveryTimesComponent } from './delivery-times/delivery-times.component';
import { ExchangeReturnsComponent } from './exchange-returns/exchange-returns.component';
import { CraftsmenComponent } from './craftsmen/craftsmen.component';
import { CraftsmenDetailComponent } from './craftsmen-detail/craftsmen-detail.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { DialogPreciosProducto, ProductDetailComponent } from './product-detail/product-detail.component';
import { CorporativeGiftsComponent } from './corporative-gifts/corporative-gifts.component';
import { PurchaseProcess1Component } from './purchase-process1/purchase-process1.component';
import { PurchaseProcess2Component } from './purchase-process2/purchase-process2.component';
import { PurchaseProcess3Component } from './purchase-process3/purchase-process3.component';
import { ComplaintsBookComponent } from './complaints-book/complaints-book.component';
import { LicensesComponent } from './licenses/licenses.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { ProyectHorecaComponent } from './proyect-horeca/proyect-horeca.component';
import { DialogInfoTienda, StoreInfoComponent } from './store-info/store-info.component';
import { PrivacyPoliciesComponent } from './privacy-policies/privacy-policies.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DemoMaterialModule,
    FlexLayoutModule,
    SharedModule,
    HttpClientModule,
    RouterModule.forChild(PortalRoutes)
  ],
  declarations: [
    HomeComponent,
    AboutUsComponent,
    QuestionsComponent,
    TermsConditionsComponent,
    ContactComponent,
    DeliveryTimesComponent,
    ExchangeReturnsComponent,
    CraftsmenComponent,
    CraftsmenDetailComponent,
    BlogComponent,
    BlogDetailComponent,
    ProductDetailComponent,
    CorporativeGiftsComponent,
    PurchaseProcess1Component,
    DialogPreciosProducto,
    PurchaseProcess2Component,
    PurchaseProcess3Component,
    ComplaintsBookComponent,
    LicensesComponent,
    ShoppingCartComponent,
    ProyectHorecaComponent,
    StoreInfoComponent,
    DialogInfoTienda,
    PrivacyPoliciesComponent
  ]
})
export class PortalModule { }
<div class="section_general">
    <!-- BANNER PREGUNTAS -->
    <div class="banner banner_terminos">
        <h2>Términos y Condiciones<br><p class="mb-0">Regalos Corporativos</p></h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-8 px-3 mx-auto tiempos-content">
        <!-- TIEMPOS 1 -->
        <div class="terminos1 terminospt">
            <h2><img src="assets/images/times/camion.png" class="img-fluid me-2 me-md-3">Pedidos de Regalos Corporativos</h2>
            <ul class="mb-4">
                <li><p>Se puede comprar en nuestro portal de <span class="text-dorado font-bold">Regalos Corporativos B2B</span>, accediendo al catálogo una vez creado tu perfil en la web (usuario y contraseña).</p></li>
                <li><p>En nuestro portal de regalos corporativos, se pueden solicitar cotizaciones, desarrollos de muestras y hasta hacer directamente tu pedido.</p></li>
            </ul>
        </div>
        <div class="terminos1">
            <h2><img src="assets/images/times/camion.png" class="img-fluid me-2 me-md-3">Tiempo de Producción</h2>
            <ul class="mb-4">
                <li>
                    <p>Al no contar con stock de nuestros productos, todos los proyectos de regalos corporativos se fabrican a pedido. El tiempo de producción depende de lo siguiente:</p>
                    <ul>
                        <li><p>Tamaño del pedido (cantidades).</p></li>
                        <li><p>Complejidad de la técnica de producción del producto elegido.</p></li>
                        <li><p>Disponibilidad de los talleres de producción.</p></li>
                    </ul>
                </li>
                <li><p>El tiempo acordado con el cliente se empieza a contabilizar una vez confirmada la Orden de Compra y/o aprobada la muestra.</p></li>
            </ul>
        </div>
        <div class="terminos1">
            <h2><img src="assets/images/times/camion.png" class="img-fluid me-2 me-md-3">Cobertura de Envíos</h2>
            <ul class="mb-4">
                <li><p>El precio cotizado, incluye entrega gratis en el almacén u oficina del cliente en Lima Metropolitana y Callao.</p></li>
                <li><p>En caso el cliente se encuentre en provincia, el envío corre por cuenta del cliente. Nuestro precio en este caso incluye embalaje de protección.</p></li>
                <li><p>En caso el cliente desee cotización de envíos al extranjero, estamos en la capacidad de poder cotizar el servicio.</p></li>
                <li><p>En caso el cliente necesite entrega personalizada de cada uno de los regalos, a nivel nacional, estamos en la capacidad de cotizar el servicio logístico a nivel nacional.</p></li>
            </ul>
        </div>
        <div class="terminos1">
            <h2><img src="assets/images/times/camion.png" class="img-fluid me-2 me-md-3">Términos de Pago</h2>
            <ul class="mb-4">
                <li><p>50% de adelanto, saldo contra entrega, mediante transferencia o depósito bancario.</p></li>
                <li><p>Pago con tarjeta de crédito.</p></li>
                <li><p>En caso el cliente requiera otro término de pago, se puede analizar.</p></li>
            </ul>
        </div>
        <div class="terminos1">
            <h2><img src="assets/images/times/camion.png" class="img-fluid me-2 me-md-3">Personalización</h2>
            <ul class="mb-4">
                <li>
                    <p>El precio cotizado incluye de cortesía lo siguiente:</p>
                    <ul>
                        <li><p>Hangtag informativo (arte proporcionado por el cliente).</p></li>
                        <li><p>Empaque en caja microcoarrugado o dúplex, con logo del cliente a un color (arte del logo proporcionado por el cliente).</p></li>
                    </ul>
                </li>
                <li><p>Cualquier otro requerimiento especial que el cliente solicite, ya sea empaques especiales, placas recordatorias, grabados, etc., son posibles por un costo adicional.</p></li>
                <li><p>Los productos son 100% personalizables, de acuerdo a los requerimientos del cliente. Se pueden incorporar los colores corporativos de la empresa o marca al producto.</p></li>
                <li><p>En el caso de los logos también es posible, previo análisis de la complejidad del logo y de la técnica de producción.</p></li>
            </ul>
        </div>
        <br><br>
    </div>
</div>
<div class="section_general">
    <!-- BANNER COMPRA -->
    <div class="banner banner_compra3">
        <h2>¡Compra Realizada!</h2>
    </div>

    <div class="col-12 col-md-8 col-lg-6 mx-auto order1_1">
        <div class="msg-end text-center">
            <h2>¡GRACIAS!</h2>
            <p>Tu compra ha sido realizada con éxito, en breve te enviaremos una confirmación de tu pedido <span class="color_dorado d-none">#3123123</span> a tu correo electrónico.</p>
            <div class="text-center">
                <a routerLink="/regalosCorporativos" mat-raised-button class="btn-blanco-p btn-ver-mas">VER MÁS PRODUCTOS</a>
            </div>
        </div>

    </div>
</div>
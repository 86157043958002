import { Usuario } from './../../models/Usuario';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmpresaAuth } from 'src/app/models/EmpresaAuth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private reqLogin = new BehaviorSubject<any>(false);
  
  constructor(private http: HttpClient) { 
    this.currentUserSubject = new BehaviorSubject<Usuario>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): Usuario {
    return this.currentUserSubject.value;
  }

  triggerReqLogin(param: any) {
    return this.reqLogin.next(param);
  }

  getReqLogin(): BehaviorSubject<any> {
    return this.reqLogin;
  }

  login(username: string, password: string) {
    console.log(username, password);
    return this.http.post<any>(`${environment.apiURL}/intranet/login`, { emailorphone: username, password: password })
      .pipe(map((user: any) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user.data[0]));
        this.currentUserSubject.next(user.data[0]);
        return user;
      }));
  }

  register(name:string, lastname:string, username: string, password: string) {
    console.log(name, lastname, username, password);
    return this.http.post<any>(`${environment.apiURL}/web/user/register`, { name: name, lastName: lastname, mail: username, password: password })
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next({});
  }

}

<!-- ============================================================== -->
<!-- MenuFooterMovil -->
<!-- ============================================================== -->
<div class="menuFooterMovil d-md-none">
    <div class="d-flex justify-content-between align-items-center">
        <button class="btn">
            <img src="assets/images/header/icono1.png" class="navbar-brand-img">
        </button>
        <button class="btn">
            <img src="assets/images/header/icono2.png" class="navbar-brand-img">
        </button>
        <button class="btn">
            <img src="assets/images/header/icono3.png" class="navbar-brand-img">
        </button>
    </div>
</div>
<!-- ============================================================== -->
<!-- MenuFooterMovil -->
<!-- ============================================================== -->
<div class="footer-pages">
    <div class="row col-lg-11 mx-auto justify-content-center mb-4 row-cols-md-4 row-cols-lg-auto align-items-md-center">
        <div class="col-auto text-center">
            <img src="assets/images/footer/proinnovate.png" class="img-fluid" alt="Pro innóvate">
        </div>
        <div class="col-auto text-center">
            <img src="assets/images/footer/ministerio.png" class="img-fluid" alt="Ministerio de la Producción">
        </div>
        <div class="col-auto text-center">
            <img src="assets/images/footer/berrocal.png" class="img-fluid" alt="Berrocal">
        </div>
        <div class="col-auto text-center">
            <img src="assets/images/footer/wfto.png" class="img-fluid" alt="World Fair Trade">
        </div>
    </div>
    <div class="row col-lg-11 mx-auto">
        <div class="col-12 col-lg-6 col-xl-6">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-auto col-footer">
                    <h3>SERVICIO AL CLIENTE</h3>
                    <!-- <div><a routerLink="/preguntas-frecuentes" class="d-inline-block">Preguntas Frecuentes</a></div> -->
                    <div><a routerLink="/politicas-privacidad" routerLinkActive="active-link" class="d-inline-block">Políticas de Privacidad</a></div>
                    <div><a routerLink="/cambios-devoluciones" routerLinkActive="active-link" class="d-inline-block">Cambios y Devoluciones</a></div>
                    <div><a routerLink="/terminos-condicionesRC" routerLinkActive="active-link" class="d-inline-block">Términos y Condiciones RC</a></div>
                </div>
                <div class="col-auto col-footer col-account">
                    <h3>NOSOTROS</h3>
                    <div><a routerLink="/nosotros" routerLinkActive="active-link" class="d-inline-block">Conócenos</a></div>
                    <div><a routerLink="/artesanos" routerLinkActive="active-link" class="d-inline-block">Artesanos</a></div>
                    <div><a routerLink="/contactenos" routerLinkActive="active-link" class="d-inline-block">Contáctenos</a></div>
                    <div><a routerLink="/blog" routerLinkActive="active-link" class="d-inline-block">Blog</a></div>
                </div>
                <div class="col-auto col-footer d-none d-md-block d-lg-none d-xl-block">
                    <h3>MI CUENTA</h3>
                    <div><a href="javascript:void(0)" class="d-inline-block">Mis datos</a></div>
                    <div><a href="javascript:void(0)" class="d-inline-block">Mis Pedidos</a></div>
                    <div><a href="javascript:void(0)" class="d-inline-block">Mensajes</a></div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-6">
            <div class="row row-cols-3 justify-content-center justify-content-lg-start">
                <div class="col-12 col-md-auto col-xl col-footer text-center text-md-start">
                    <h3>REDES SOCIALES</h3>
                    <div class="footer-redes">
                        <a href="https://www.facebook.com/piskaperu" target="_blank" class="d-inline-block me-3 icon_redes">
                            <img src="assets/images/footer/facebook.png" alt="Facebook" data-hover="false">
                            <img src="assets/images/footer/facebook-hover.png" alt="Facebook" data-hover="true">
                        </a>
                        <a href="https://www.instagram.com/piskaperu/" target="_blank" class="d-inline-block me-3 icon_redes">
                            <img src="assets/images/footer/instagram.png" alt="Instagram" data-hover="false">
                            <img src="assets/images/footer/instagram-hover.png" alt="Instagram" data-hover="true">
                        </a>
                        <a href="https://www.linkedin.com/company/ambientes-y-tendencias-sac/?originalSubdomain=pe" target="_blank" class="d-inline-block icon_redes">
                            <img src="assets/images/footer/linkedin.png" alt="Linkedin" data-hover="false">
                            <img src="assets/images/footer/linkedin-hover.png" alt="Linkedin" data-hover="true">
                        </a>
                    </div>
                </div>
                <div class="col-12 col-md-auto col-xl col-footer text-center text-md-start">
                    <h3>MÉTODOS DE PAGO</h3>
                    <div class="footer-payment">
                        <img src="assets/images/footer/visa.png" alt="Visa" class="img-fluid me-3">
                        <img src="assets/images/footer/mastercard.png" alt="Mastercad" class="img-fluid me-3">
                        <img src="assets/images/footer/diners.png" alt="Diners Club" class="img-fluid">
                    </div>
                </div>
                <div class="col-12 col-md-auto col-xl col-footer text-center">
                    <a routerLink="/libro-reclamaciones">
                        <img src="assets/images/footer/libro.png" alt="Libro de Reclamaciones" class="img-fluid">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <br><br class="d-xxl-block">
    <div class="row col-lg-11 mx-auto footer-page2">
        <div class="col-md-6 text-center text-md-start">
            <span class="px-3 py-md-3">© Piska. Todos los derechos reservados.</span>
        </div>
        <div class="col-md-6 text-center text-md-end">
            <a href="https://katanastudio.pe" target="_blank" class="p-3">Diseñado por Katana Studio</a>
        </div>
    </div>
</div>
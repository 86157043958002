<div class="bg-white text-center px-4 py-3">
    <img [src]="tienda.imagen" class="img-fluid">
</div>
<div mat-dialog-content class="text-modal">
    <h2>Dirección</h2>
    <p>{{tienda.direccion}}</p>
    <h2>Teléfono</h2>
    <p>{{tienda.telefono}}</p>
    <h2>Correo</h2>
    <p style="line-break: anywhere;">{{tienda.correo}}</p>
    <h2 class="mb-3">Redes Sociales</h2>
    <div>
        <a [href]="tienda.link_fb" *ngIf="tienda.link_fb != ''" target="_blank" class="d-inline-block me-2 icon_redes">
            <img src="assets/images/stores/facebook.png" alt="Facebook" data-hover="false">
            <img src="assets/images/stores/facebook-hover.png" alt="Facebook" data-hover="true">
        </a>
        <a [href]="tienda.link_ig" *ngIf="tienda.link_ig != ''" target="_blank" class="d-inline-block me-2 icon_redes">
            <img src="assets/images/stores/instagram.png" alt="Instagram" data-hover="false">
            <img src="assets/images/stores/instagram-hover.png" alt="Instagram" data-hover="true">
        </a>
        <a [href]="tienda.link_tw" *ngIf="tienda.link_tw != ''" target="_blank" class="d-inline-block icon_redes">
            <img src="assets/images/stores/twitter.png" alt="Twitter" data-hover="false">
            <img src="assets/images/stores/twitter-hover.png" alt="Twitter" data-hover="true">
        </a>
    </div>
</div>
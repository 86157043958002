<div class="section_general">
    <!-- BANNER HORECA -->
    <div class="banner banner_horeca">
        <h2>Proyectos Horeca</h2>
    </div>

    <div class="col-12 col-md-11 col-xxl-10 mx-auto px-3">
        <div class="proyecto1">
            <div class="row">
                <div class="col-md-6 mt-4 mt-md-0 order-1 order-md-0 pe-xl-4">
                    <img src="assets/images/proyect_horeca/01.jpg" class="img-fluid mb-4">
                    <img src="assets/images/proyect_horeca/02.jpg" class="img-fluid">
                </div>
                <div class="col-md-6 order-0 order-md-1">
                    <h2 class="text-dorado font-bolder">Productos artesanales, para una "hospitalidad hecha a mano"</h2>
                    <p>Ponemos a disposición de los proyectos hoteleros y de restaurantes, nuestro know how en producción hecha a mano con técnicas de producción peruanas; y nuestra experiencia de 40 años como exportadores, enviando artesanía peruana de diseño a los 5 continentes. Hemos trabajado proyectos de hoteles, spas y restaurantes, en ciudades como Lima, Cusco, Londres, Dubái, Miami, Nueva York, Paris, Mónaco, Doha, Abu Dabi, Riad, entre otras. A la fecha, a nivel mundial hemos ejecutado en total 49 proyectos FF&E, de los cuales 25 de ellos son restaurantes; y más de 1200 habitaciones en hoteles de diferentes ciudades del mundo, fueron equipadas con mobiliario y/o artículos decorativos hechos a mano en Perú por nosotros. A continuación, podrás encontrar nuestro Dossier de Proyectos de Hospitalidad.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section_general">
    <!-- Slider Home -->
    <div id="carouselHome" class="carousel slide carousel-home" data-bs-ride="carousel" data-bs-pause=false>
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="0" class="active" aria-current="true"
                aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="2" aria-label="Slide 3"></button>
            <button type="button" data-bs-target="#carouselHome" data-bs-slide-to="3" aria-label="Slide 4"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active" style="background-image: url(assets/images/home/01.png);">
                <div class="slider-text-content">
                    <div class="slider-text">
                        <p class="mb-0">DESCUBRE NUESTROS</p>
                        <h2>REGALOS</h2>
                        <h3 class="mb-5">CORPORATIVOS</h3>
                        <div class="text-center text-md-start">
                            <a routerLink="/regalosCorporativos" mat-raised-button class="btn-blanco-p">VER PRODUCTOS</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item" style="background-image: url(assets/images/home/02.png);">
                <div class="slider-text-content">
                    <div class="slider-text">
                        <p class="mb-0">CONOCE NUESTROS</p>
                        <h2>PROYECTOS</h2>
                        <h3 class="mb-5">HORECA</h3>
                        <div class="text-center text-md-start">
                            <a routerLink="/regalosCorporativos" mat-raised-button class="btn-blanco-p">VER PRODUCTOS</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item" style="background-image: url(assets/images/home/03.png);">
                <div class="slider-text-content">
                    <div class="slider-text">
                        <p class="mb-0">CONOCE A</p>
                        <h2>NUESTROS</h2>
                        <h3 class="mb-5">ARTESANOS</h3>
                        <div class="text-center text-md-start">
                            <a routerLink="/regalosCorporativos" mat-raised-button class="btn-blanco-p">VER PRODUCTOS</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item" style="background-image: url(assets/images/home/04.png);">
                <div class="slider-text-content">
                    <div class="slider-text">
                        <p class="mb-0">VISITA NUESTRAS</p>
                        <h2>TIENDAS</h2>
                        <h3 class="mb-5">VIRTUALES</h3>
                        <div class="text-center text-md-start">
                            <a routerLink="/regalosCorporativos" mat-raised-button class="btn-blanco-p">VER PRODUCTOS</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselHome" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselHome" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <div class="container-home">
        <!-- HOME 1 -->
        <div class="home1">
            <div class="row mx-0">
                <div class="col-12 col-md-6 col-lg-4 px-0 home1-col">
                    <div class="home1-text">
                        <h2 class="home1-text-title">Las mejores opciones para tu empresa...</h2>
                        <p class="mb-5 home-text-p">Contamos con una gran variedad de regalos corporativos, y recuerda que si tienes
                            una idea en mente, ¡nosotros podemos hacerla realidad!</p>
                        <div class="text-center text-md-start">
                            <a routerLink="/regalosCorporativos" mat-raised-button class="btn-dorado-p">VER MÁS</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 px-5 home1-col">
                    <div class="img-product" style="background-image: url(assets/images/home/product01.jpg);"></div>
                    <div class="name-product text-center">
                        <h2>Toro de Pucará</h2>
                        <h3><span class="pe-2">S/</span>120.00</h3>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 px-5 home1-col">
                    <div class="img-product" style="background-image: url(assets/images/home/product02.jpg);"></div>
                    <div class="name-product text-center">
                        <h2>Toro de Pucará</h2>
                        <h3><span class="pe-2">S/</span>120.00</h3>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 px-5 home1-col">
                    <div class="img-product" style="background-image: url(assets/images/home/product03.jpg);"></div>
                    <div class="name-product text-center">
                        <h2>Toro de Pucará</h2>
                        <h3><span class="pe-2">S/</span>120.00</h3>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 px-5 home1-col">
                    <div class="img-product" style="background-image: url(assets/images/home/product04.jpg);"></div>
                    <div class="name-product text-center">
                        <h2>Toro de Pucará</h2>
                        <h3><span class="pe-2">S/</span>120.00</h3>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4 px-5 home1-col">
                    <div class="img-product" style="background-image: url(assets/images/home/product05.jpg);"></div>
                    <div class="name-product text-center">
                        <h2>Toro de Pucará</h2>
                        <h3><span class="pe-2">S/</span>120.00</h3>
                    </div>
                </div>
            </div>
        </div>

        <!-- HOME 2 -->
        <div class="home2">
            <div class="home2-banner">
                <div class="col-12 col-lg-8 col-xl-6">
                    <h3>Regalos Corporativos</h3>
                    <h2>Personalizados</h2>
                    <p class="mb-2rem">Si deseas personalizar nuestros productos con tu logotipo, colores corporativos o
                        realizar una
                        idea que tienes en mente, no dudes en comunicarte con nosotros.</p>
                    <div class="text-center text-md-start">
                        <a routerLink="/contactenos" mat-raised-button class="btn-dorado-p">ESCRÍBENOS</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- HOME 3 -->
        <div class="home3">
            <div class="row justify-content-md-between home3-1 mb-5 mb-md-0 row-cols-2">
                <div class="col-12 col-md">
                    <h2 class="mb-3 mb-md-0 home3-1-title">Puedes encontrar nuestros productos <br class="d-none d-lg-block"> en las diferentes tiendas...
                    </h2>
                </div>
                <div class="col-12 col-md-auto text-center text-md-right">
                    <a routerLink="/tiendas" mat-raised-button class="btn-dorado-p">VER MÁS</a>
                </div>
            </div>
            <div class="row home3-2">
                <div class="col-3 col-md-3">
                    <div class="img-product" style="background-image: url(assets/images/home/market01.jpg);"></div>
                </div>
                <div class="col-3 col-md-3">
                    <div class="img-product" style="background-image: url(assets/images/home/market02.jpg);"></div>
                </div>
                <div class="col-3 col-md-3">
                    <div class="img-product" style="background-image: url(assets/images/home/market03.jpg);"></div>
                </div>
                <div class="col-3 col-md-3">
                    <div class="img-product" style="background-image: url(assets/images/home/market04.jpg);"></div>
                </div>
            </div>
            <div class="row home3-3 row-cols-2 row-cols-md-5 justify-content-center pt-4 pt-md-0">
                <div class="col text-center">
                    <img src="assets/images/home/linio.png" alt="Linio" class="img-fluid">
                </div>
                <div class="col text-center">
                    <img src="assets/images/home/juntoz.png" alt="Linio" class="img-fluid">
                </div>
                <div class="col text-center">
                    <img src="assets/images/home/lumingo.png" alt="Linio" class="img-fluid">
                </div>
                <div class="col text-center">
                    <img src="assets/images/home/tiendaCrema.png" alt="Linio" class="img-fluid">
                </div>
                <div class="col text-center">
                    <img src="assets/images/home/tiendaAliancista.png" alt="Linio" class="img-fluid">
                </div>
            </div>
        </div>

        <!-- HOME 4 -->
        <div class="home4">
            <h2 class="home4-title">También tenemos...</h2>
            <div class="row home4-1">
                <div class="col-12 col-md-6 col-xl-7 home4-1-img order-1 order-md-0">
                    <div style="background-image: url(assets/images/home/hospitalidad.jpg);"></div>
                </div>
                <div class="col-12 col-md-6 col-xl-5 home4-1-text order-0 order-md-1">
                    <h2>PROYECTOS DE<br>HOSPITALIDAD</h2>
                    <p class="home-text-p">Hemos trabajado proyectos de hoteles, spas y restaurantes, en ciudades como Lima, Cusco, Londres, Dubái, Miami, Nueva York, Paris, Mónaco, Doha, Abu Dabi, Riad, entre otras.</p>
                    <div class="text-center text-md-start">
                        <a routerLink="/proyectos" mat-raised-button class="btn-dorado-p">VER MÁS</a>
                    </div>
                </div>
            </div>
            <div class="row home4-1">
                <div class="col-12 col-md-6 col-xl-5 home4-1-text">
                    <h2>PROYECTOS DE<br>LICENCIAMIENTO</h2>
                    <p class="home-text-p">La artesanía peruana se reinventa y busca recuperarse con la venta de productos oficiales, licenciados por los Clubes más importantes del fútbol peruano.</p>
                    <div class="text-center text-md-start">
                        <a routerLink="/licencias" mat-raised-button class="btn-dorado-p">VER MÁS</a>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-xl-7 home4-1-img">
                    <div style="background-image: url(assets/images/home/licenciaUniversitario.jpg);"></div>
                </div>
            </div>
        </div>

        <!-- HOME 5 -->
        <div class="home5">
            <h2 class="home4-title text-center">LO ÚLTIMO EN NUESTRO BLOG</h2>
            <div class="row">
                <div class="col-12 col-xl-7">
                    <div class="home5-item home5-item1 mb-4 mb-xl-0" style="background-image: url(assets/images/home/licenciaAlianza.jpg);">
                        <div class="d-md-flex mx-0 justify-content-md-between">
                            <div class="home5-item-text max text-center text-md-start mb-3 mb-md-0">
                                Realizamos los productos<br>oficiales de Alianza Lima
                            </div>
                            <div class="text-center text-md-end">
                                <a routerLink="/blog" mat-raised-button class="btn-blanco-p">VER NOTA</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-5">
                    <div class="home5-item mb-4 mb-xl-5" style="background-image: url(assets/images/home/e2.png);">
                        <div class="d-md-flex mx-0 justify-content-md-between">
                            <div class="home5-item-text mini px-0 text-center text-md-start mb-3 mb-md-0">
                                Realizamos los productos<br>oficiales de Alianza Lima
                            </div>
                            <div class="text-center text-md-end">
                                <a routerLink="/blog" mat-raised-button class="btn-blanco-p">VER NOTA</a>
                            </div>
                        </div>
                    </div>
                    <div class="home5-item" style="background-image: url(assets/images/home/e2.png);">
                        <div class="d-md-flex mx-0 justify-content-md-between">
                            <div class="home5-item-text mini px-0 text-center text-md-start mb-3 mb-md-0">
                                Realizamos los productos<br>oficiales de Alianza Lima
                            </div>
                            <div class="text-center text-md-end">
                                <a routerLink="/blog" mat-raised-button class="btn-blanco-p">VER NOTA</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <!-- HOME 6 -->
        <div class="home4">
            <div class="row home4-1">
                <div class="col-12 col-md-5 col-lg-5 col-xl-4 home4-1-text pt-4 mb-md-0 ps-md-3">
                    <h2>Nuestros<br>Artesanos</h2>
                    <p class="home-text-p">Conoce a quienes están detrás de cada una de nuestras piezas artesanales, aquellos que ponen amor
                        y pasión al realizar cada trabajo.</p>
                    <div class="text-center text-md-start">
                        <a routerLink="/artesanos" mat-raised-button class="btn-dorado-p">VER MÁS</a>
                    </div>
                </div>
                <div class="col-12 col-md-7 col-lg-7 col-xl-8">
                    <div id="carouselHome6" class="carousel slide carouselHome6" data-bs-ride="carousel"
                        data-bs-pause=false>
                        <div class="carousel-inner">
                            <div class="carousel-item active"
                                style="background-image: url(assets/images/home/artesanos/artesanos01.jpg);"></div>
                            <div class="carousel-item" style="background-image: url(assets/images/home/artesanos/artesanos02.jpg);">
                            </div>
                            <div class="carousel-item" style="background-image: url(assets/images/home/artesanos/artesanos03.jpg);">
                            </div>
                            <div class="carousel-item" style="background-image: url(assets/images/home/artesanos/artesanos04.jpg);">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselHome6"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselHome6"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- HOME 7 -->
        <div class="home7 px-3 px-md-0">
            <app-marcas-slider></app-marcas-slider>
        </div>
    </div>
</div>
<div class="section_general">
    <!-- BANNER ARTESANOS -->
    <div class="banner banner_artesanos">
        <h2><span>Artesanos</span></h2>
    </div>

    <div class="content-artesanos">
        <!-- ARTESANOS 1 -->
        <div class="artesano1">
            <div class="row mx-0">
                <div class="col-md-6 ps-md-0 px-0 py-4 pe-md-4 py-md-5">
                    <a href="/artesanos" class="btn-return">
                        <span class="material-icons">chevron_left</span> Volver
                    </a>
                    <h2 class="artesano_h2 mb-2">Angel Román</h2>
                    <h3 class="artesano_h3 mb-4">CERAMISTA</h3>
                    <p class="artesano_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit culpa placeat
                        sed eveniet praesentium assumenda. Dignissimos iste magni, suscipit recusandae veniam neque
                        exercitationem saepe, ab voluptatum molestias aliquam perferendis deserunt, dolorem tempore
                        dicta architecto quibusdam corporis expedita dolore quam facere ipsa. Maiores, in aspernatur!
                        Nam vitae minima molestiae distinctio aperiam?</p>
                    <p class="artesano_p">Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit culpa placeat
                        sed eveniet praesentium assumenda. Dignissimos iste magni, suscipit recusandae veniam neque
                        exercitationem saepe, ab voluptatum molestias aliquam perferendis deserunt, dolorem tempore
                        dicta architecto quibusdam corporis expedita dolore quam facere ipsa. Maiores, in aspernatur!
                        Nam vitae minima molestiae distinctio aperiam?</p>
                </div>
                <div class="col-md-6 artesano1_img">
                    <div id="carouselExampleControls" class="carousel slide content-slider-artesanos" data-bs-ride="carousel" data-bs-pause=false>
                        <div class="carousel-inner h-100">
                            <div class="carousel-item active slider_artesano" ></div>
                            <div class="carousel-item slider_artesano"></div>
                            <div class="carousel-item slider_artesano"></div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</div>
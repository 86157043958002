<div class="section_general">
    <!-- BANNER PREGUNTAS -->
    <div class="banner banner_compra1">
        <h2>Proceso de Compra</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 mx-auto order1_1 position-relative">
        <form class="mb-5" [formGroup]="compraForm" (ngSubmit)="onSubmitCompra()">
            <div class="row">
                <div class="col-12 mb-3">
                    <h2 class="color_dorado">Información de envío</h2>
                    <p>El costo del envío se calculará al guardar la dirección.</p>
                </div>
                <div class="col-12 col-md-8 mb-3">
                    <div class="form-group row">
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Nombres<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="nombres" [class.is-invalid]="getNombres" required>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Apellidos<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="apellidos" [class.is-invalid]="getApellidos" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Celular<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" maxlength="9" formControlName="celular" [class.is-invalid]="getCelular" required>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Correo electrónico<span class="text-danger">*</span></label>
                            <input type="email" class="form-control" formControlName="correo" [class.is-invalid]="getCorreo" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">RUC</label>
                            <input type="text" class="form-control" formControlName="ruc" maxlength="11">
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Razón Social</label>
                            <input type="text" class="form-control" formControlName="razonSocial">
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Región / Provincia<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="region" [class.is-invalid]="getRegion" required>
                        </div>
                        <div class="col-12 col-md-6 mb-3">
                            <label class="form-label">Distrito<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="distrito" [class.is-invalid]="getDistrito" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 mb-3">
                            <label class="form-label">Dirección<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="direccion" [class.is-invalid]="getDireccion" required>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12 mb-3">
                            <label class="form-label">Referencia (opcional)</label>
                            <textarea class="form-control" rows="5" formControlName="referencia"></textarea>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card-resumen mx-auto">
                        <div class="card text-center px-3 mb-3">
                            <div class="color_dorado p-3 font-bolder">
                                RESUMEN DEL PEDIDO
                            </div>
                            <div class="card-body body-resumen">
                                <div class="d-flex mb-3 justify-content-between">
                                    <span class="d-inline-block font-bolder color_dorado">Producto:</span>
                                    <span class="d-inline-block text-end">{{producto.name}}</span>
                                </div>
                                <div class="d-flex mb-3 justify-content-between">
                                    <span class="d-inline-block font-bolder color_dorado">Cantidad:</span>
                                    <span class="d-inline-block">{{amountProduct}}</span>
                                </div>
                                <div class="d-flex mb-5 justify-content-between">
                                    <span class="d-inline-block font-bolder color_dorado">Precio:</span>
                                    <span class="d-inline-block">S/ {{producto.price | number:'1.2'}}</span>
                                </div>
                            </div>
                            <div class="p-3 color_dorado d-flex justify-content-between font-bolder">
                                <span class="d-inline-block">Total:</span>
                                <span class="d-inline-block">S/ {{producto.price | number:'1.2'}}</span>
                            </div>
                        </div>
                        <div class="col-12 text-center">
                            <button type="submit" mat-raised-button class="btn-dorado-p w-100" [disabled]="loading">
                                <div class="spinner-border text-light spinner-border-sm" role="status" *ngIf="loading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                FINALIZAR PEDIDO
                            </button>
                            <p class="text-success mt-3 font-bold text-start" *ngIf="submitSuccess">Procesando ...</p>
                            <p class="text-danger mt-3 font-bold text-start" *ngIf="submitError">Hubo un error, inténtelo de nuevo.</p>
                            <!-- <a href="" class="d-inline-block mt-3 link_dorado">VOLVER AL CARRITO</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="order1_2">
            <!-- REGALOS -->
            <div class="regalos3">
                <div class="row">
                    <div class="col-lg-6">
                        <h2>Regalos Corporativos<br><span>Personalizados</span></h2>
                    </div>
                    <div class="col-lg-6 pt-md-2">
                        <p>Si deseas personalizar nuestros productos con tu logotipo, colores corporativos o realizar una idea que tienes en mente, no dudes en comunicarte con nosotros.</p>
                    </div>
                    <div class="col-12 pt-3">
                        <a routerLink="/contactenos" mat-raised-button href="" class="btn-dorado-p">ESCRÍBENOS</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="bodyBlock" *ngIf="loadingData">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
    </div>
</div>
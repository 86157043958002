<div class="section_general">
    <!-- BANNER PREGUNTAS -->
    <div class="banner banner_contacto">
        <h2>Estamos para ti</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-8 mx-auto contacto1">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div class="col-12">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Nombres y Apellidos</mat-label>
                        <input matInput type="text" formControlName="nombreApellido" [class.is-invalid]="getNombreApellido" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-md-6 col-xl-5">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Celular</mat-label>
                        <input matInput type="text" formControlName="celular" maxlength="9" [class.is-invalid]="getCelular" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-xl-5 ms-auto">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Correo</mat-label>
                        <input matInput type="email" formControlName="correo" [class.is-invalid]="getCorreo" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-md-6 col-xl-5">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Empresa</mat-label>
                        <input matInput type="text" formControlName="empresa" [class.is-invalid]="getEmpresa" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-xl-5 ms-auto">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Página Web</mat-label>
                        <input matInput type="link" formControlName="paginaWeb" [class.is-invalid]="getPaginaWeb" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12 col-md-6 col-xl-5">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Dirección / Distrito</mat-label>
                        <input matInput type="text" formControlName="direccion" [class.is-invalid]="getDireccion" required>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-xl-5 ms-auto">
                    <mat-form-field class="form-field-contact">
                        <mat-label>País</mat-label>
                        <input matInput type="text" formControlName="pais" [class.is-invalid]="getPais" required>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-12">
                    <mat-form-field class="form-field-contact">
                        <mat-label>Mensaje</mat-label>
                        <textarea matInput rows="5" formControlName="mensaje" [class.is-invalid]="getMensaje" required></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="form-group row mt-4">
                <div class="col-12 text-center">
                    <button mat-raised-button type="submit" class="btn-dorado-p" [disabled]="loading">
                        <div class="spinner-border text-light spinner-border-sm" role="status" *ngIf="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        ENVIAR
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
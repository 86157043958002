<div class="section_general">
    <!-- BANNER POLITICAS -->
    <div class="banner banner_politicas">
        <h2>Políticas de Privacidad</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-8 mx-auto terminos1">
        <div class="text-center">
            <img src="assets/images/terms/piska.png" alt="" class="img-fluid me-2">
        </div>
        <div class="pt-5">
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur ratione quibusdam minus molestias fugiat ullam dolor quas architecto. Voluptate tempora ipsum corporis ducimus exercitationem. Quaerat quas magni sunt nesciunt eius. Vitae delectus voluptatibus eius velit, architecto deserunt quo minus beatae nemo officiis? Asperiores possimus autem eaque provident recusandae dolore reprehenderit!</p>
        </div>
        <br>
        <mat-divider class="mat-divider-custom"></mat-divider>
        <br><br>
        <div>
            <h2 class="title-terms"><img src="assets/images/terms/icono.png" class="img-fluid me-2">Capacidad legal</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur ratione quibusdam minus molestias fugiat ullam dolor quas architecto. Voluptate tempora ipsum corporis ducimus exercitationem. Quaerat quas magni sunt nesciunt eius. Vitae delectus voluptatibus eius velit, architecto deserunt quo minus beatae nemo officiis? Asperiores possimus autem eaque provident recusandae dolore reprehenderit!</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium atque in quidem omnis ipsa quas obcaecati sapiente? Est, et facilis.</p>
        </div>
        <br><br>
        <div>
            <h2 class="title-terms"><img src="assets/images/terms/icono.png" class="img-fluid me-2">Registro y uso del sitio</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur ratione quibusdam minus molestias fugiat ullam dolor quas architecto. Voluptate tempora ipsum corporis ducimus exercitationem. Quaerat quas magni sunt nesciunt eius. Vitae delectus voluptatibus eius velit, architecto deserunt quo minus beatae nemo officiis? Asperiores possimus autem eaque provident recusandae dolore reprehenderit!</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium atque in quidem omnis ipsa quas obcaecati sapiente? Est, et facilis.</p>
        </div>
        <br><br>
        <div>
            <h2 class="title-terms"><img src="assets/images/terms/icono.png" class="img-fluid me-2">Modificaciones de acuerdo</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur ratione quibusdam minus molestias fugiat ullam dolor quas architecto. Voluptate tempora ipsum corporis ducimus exercitationem. Quaerat quas magni sunt nesciunt eius. Vitae delectus voluptatibus eius velit, architecto deserunt quo minus beatae nemo officiis? Asperiores possimus autem eaque provident recusandae dolore reprehenderit!</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium atque in quidem omnis ipsa quas obcaecati sapiente? Est, et facilis.</p>
        </div>
        <br><br>
        <div>
            <h2 class="title-terms"><img src="assets/images/terms/icono.png" class="img-fluid me-2">Modificaciones de acuerdo</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur ratione quibusdam minus molestias fugiat ullam dolor quas architecto. Voluptate tempora ipsum corporis ducimus exercitationem. Quaerat quas magni sunt nesciunt eius. Vitae delectus voluptatibus eius velit, architecto deserunt quo minus beatae nemo officiis? Asperiores possimus autem eaque provident recusandae dolore reprehenderit!</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium atque in quidem omnis ipsa quas obcaecati sapiente? Est, et facilis.</p>
        </div>
        <br><br>
        <div>
            <h2 class="title-terms"><img src="assets/images/terms/icono.png" class="img-fluid me-2">Procedimiento para hacer uso de este sitio de internet</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consequatur ratione quibusdam minus molestias fugiat ullam dolor quas architecto. Voluptate tempora ipsum corporis ducimus exercitationem. Quaerat quas magni sunt nesciunt eius. Vitae delectus voluptatibus eius velit, architecto deserunt quo minus beatae nemo officiis? Asperiores possimus autem eaque provident recusandae dolore reprehenderit!</p>
            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Accusantium atque in quidem omnis ipsa quas obcaecati sapiente? Est, et facilis.</p>
        </div>
        <br>
        <div class="">
            <p class="mb-0 text-dorado text-end">Última actualización 15 de Junio del 2020</p>
        </div>
    </div>
</div>
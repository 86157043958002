<div class="section_general">
    <!-- BANNER LICENCIAS -->
    <div class="banner banner_licencias">
        <h2>Licencias</h2>
    </div>

    <div class="col-12 col-md-11 mx-auto px-3">
        <div class="licencia1">
            <div class="row">
                <div class="col-md-6 pe-lg-4 pe-xxl-5">
                    <h2 class="text-dorado font-bolder">Proyectos de Licenciamiento</h2>
                    <p>La reinvención e innovación para enfrentar la pandemia no cesa y ahora es el turno de la artesanía peruana. La marca PISKA y los principales equipos de fútbol del Perú se unen con el fin de crear productos artesanales, inspirados en los colores y los valores de la cultura de cada Club y desarrollados con altos estándares de calidad y funcionalidad, a fin de que los hinchas de Perú y de cualquier parte del mundo, puedan adquirirlos.</p>
                    <p>La Artesanía es el legado cultural que heredamos todos los peruanos y debe estar a disposición de todos los peruanos, sin distinción de edad y del equipo al que pertenezcan sus corazones. Los productos están hechos por las manos de artesanos peruanos y su comercialización apoyará a los talleres de producción, les dará el soporte para desarrollarse y mejorar su calidad de vida y la de sus familias.</p>
                </div>
                <div class="col-md-6 mt-4 mt-md-0">
                    <img src="assets/images/licenses/img01.jpg" class="img-fluid d-md-none d-xl-block">
                    <div class="licencia_img d-none d-md-block d-xl-none"></div>
                </div>
            </div>
        </div>

        <div class="licencia2">
            <h2 class="text-dorado">Licenciamientos</h2>
            <p>A continuación podrás encontrar los licenciamientos oficiales de Piska.</p>
            <div class="row">
                <div class="col-md-6 pe-md-4">
                    <img src="assets/images/licenses/alianza.jpg" alt="Club Alianza Lima" class="img-fluid">
                    <h2 class="text-center text-dorado mt-3">Club Alianza Lima</h2>
                </div>
                <div class="col-md-6 ps-md-4">
                    <img src="assets/images/licenses/universitario.jpg" alt="Club Universitario de Deportes" class="img-fluid">
                    <h2 class="text-center text-dorado mt-3">Club Universitario de Deportes</h2>
                </div>
            </div>
        </div>
    </div>
</div>
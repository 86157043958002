import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-marcas-slider',
  templateUrl: './marcas-slider.component.html',
  styleUrls: ['./marcas-slider.component.scss']
})
export class MarcasSliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  slides = [
    {img: "assets/images/home/marcas/donofrio.jpg"},
    {img: "assets/images/home/marcas/vivanda.jpg"},
    {img: "assets/images/home/marcas/LAP.jpg"},
    {img: "assets/images/home/marcas/latam.jpg"},
    {img: "assets/images/home/marcas/promperu.jpg"},
    {img: "assets/images/home/marcas/luxury.jpg"},
    {img: "assets/images/home/marcas/gloria.jpg"},
    {img: "assets/images/home/marcas/cencosud.jpg"},
    {img: "assets/images/home/marcas/JWMarriott.jpg"},
    {img: "assets/images/home/marcas/adex.jpg"},
    {img: "assets/images/home/marcas/FPF.jpg"},
  ];
  slideConfig = {
    slidesToShow: 4, 
    slidesToScroll: 1,
    infinite: true,
    arrows: true,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
}

import { Usuario } from './../../models/Usuario';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/security/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm = this.fb.group({
    name_user: ['', [Validators.required]],
    lastname_user: ['', [Validators.required]],
    mail_user: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]]
  });
  loading: boolean = false;
  submitSuccess: boolean = false;
  submitError: boolean = false;
  currentUser: Usuario = {};
  returnUrl: string = '';
  msgError: string = '';

  constructor(private fb: FormBuilder, private auth: AuthenticationService, private router: Router,
    private route: ActivatedRoute, public dialogRef: MatDialogRef<RegisterComponent>) {
    this.auth.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    /*if (this.currentUser && this.currentUser.typeUser == 'customer') {
      this.router.navigate(["home"]);
    }
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';*/
  }

  get name_user() { return this.registerForm.get('name_user')?.invalid && this.registerForm.get('name_user')?.touched }
  get lastname_user() { return this.registerForm.get('lastname_user')?.invalid && this.registerForm.get('lastname_user')?.touched }
  get mail_user() { return this.registerForm.get('mail_user')?.invalid && this.registerForm.get('mail_user')?.touched }
  get password() { return this.registerForm.get('password')?.invalid && this.registerForm.get('password')?.touched; }

  onSubmit() {
    this.submitError = false;
    if (this.registerForm.invalid) {
      return Object.values(this.registerForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(control => {
            control.markAsDirty();
            control.markAsTouched();
          });
        } else {
          control.markAsDirty();
          control.markAsTouched();
        }
      });
    }
    this.loading = true;
    this.auth.register(this.registerForm.value.name_user, this.registerForm.value.lastname_user, 
      this.registerForm.value.mail_user, this.registerForm.value.password).subscribe(response => {
      console.log(response);
      if(response.data[0].Notice){
        this.msgError = response.data[0].Notice;
        this.loading = false;
        this.submitError = true;
      }else{
        this.submitSuccess = true;
        /*setTimeout(() => {
          this.router.navigate([this.returnUrl]);
          this.dialogRef.close();
        }, 1000);*/
        this.auth.login(this.registerForm.value.mail_user, this.registerForm.value.password).subscribe(response => {
          this.submitSuccess = true;
          setTimeout(() => {
            if(response.data[0].typeUser == 'admin'){
              this.router.navigate([this.returnUrl]);
            }else{
              this.router.navigate(['/home']);
            }
            this.dialogRef.close();
          }, 1000);
        }, error => {
          this.submitError = true;
          this.loading = false;
        });
      }
    }, error => {
      this.submitError = true;
      this.loading = false;
    });
  }

  openDialogLogin(): void {
    this.dialogRef.close('openLogin');
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InfoTienda{
  direccion?: string;
  telefono?: string;
  correo?: string;
  link_fb?: string;
  link_ig?: string;
  link_tw?: string;
  imagen?: string;
}

@Component({
  selector: 'app-store-info',
  templateUrl: './store-info.component.html',
  styleUrls: ['./store-info.component.scss']
})
export class StoreInfoComponent implements OnInit {

  listTiendas: InfoTienda[] = [
    {
      direccion: 'Av. El Bosque 260 y 263 San Isidro 27 Lima, Perú',
      telefono: '(01) 4403099',
      correo: 'info@galeriaindigo.com.pe',
      link_fb: 'https://www.facebook.com/GaleriaIndigo',
      link_ig: 'https://www.instagram.com/galeria.indigo',
      link_tw: '',
      imagen: 'assets/images/stores/Indigo.png'
    },
    {
      direccion: 'San Juan de Lurigancho ',
      telefono: '+51 902 041 814',
      correo: 'nacionblanquiazuloficial@gmail.com',
      link_fb: 'https://www.facebook.com/NacionBlanquiazulOficial/',
      link_ig: 'https://www.instagram.com/tiendanacionblanquiazul',
      link_tw: 'https://twitter.com/TiendaNB1901',
      imagen: 'assets/images/stores/NacionBlanquiazul.png'
    },
    {
      direccion: 'Galería Gabino: Av. la Paz 356, Tda. # 6. Miraflores',
      telefono: '(01) 4457668',
      correo: 'tiendaaliancista@gmail.com',
      link_fb: 'https://www.facebook.com/tiendaclubalianzalima',
      link_ig: 'https://www.instagram.com/tiendaaliancista/',
      link_tw: '',
      imagen: 'assets/images/stores/Alianza-LaPaz.png'
    },
    {
      direccion: 'Av. Benavides 2250, Int. 114 – Miraflores',
      telefono: '(01) 3605198',
      correo: 'tiendaclubalianzalima@gmail.com',
      link_fb: 'https://www.facebook.com/tiendaclubalianzalima',
      link_ig: 'https://www.instagram.com/tiendaaliancista/',
      link_tw: '',
      imagen: 'assets/images/stores/Alianza-Benavides.png'
    },
    {
      direccion: 'Jr. Gamarra 901 Galería Yuyi - Stand 00130 Sótano - La Victoria',
      telefono: '(01) 3605198',
      correo: 'tiendaclubalianzalima@gmail.com',
      link_fb: 'https://www.facebook.com/tiendaclubalianzalima',
      link_ig: 'https://www.instagram.com/tiendaaliancista/',
      link_tw: '',
      imagen: 'assets/images/stores/Alianza-Gamarra.png'
    }
  ]
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialogTienda(tienda: InfoTienda): void {
    const dialogRef = this.dialog.open(DialogInfoTienda, {
      width: '300px',
      panelClass: 'fondo_dialog2',
      data: tienda
    });
  }

}

@Component({
  selector: 'dialog-precios-producto',
  templateUrl: 'modal-tienda.component.html',
  styleUrls: ['./store-info.component.scss']
})
export class DialogInfoTienda {

  constructor(public dialogRef: MatDialogRef<DialogInfoTienda>,
    @Inject(MAT_DIALOG_DATA) public tienda: InfoTienda) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
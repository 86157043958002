import { CompraService } from './../../services/portal/compra.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario } from 'src/app/models/Usuario';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-purchase-process1',
  templateUrl: './purchase-process1.component.html',
  styleUrls: ['./purchase-process1.component.scss']
})
export class PurchaseProcess1Component implements OnInit {

  idProduct: number = 0;
  amountProduct: number = 0;
  loadingData: boolean = false;
  currentUser: Usuario = {};
  compraForm = this.fb.group({
    nombres: ['', Validators.required],
    apellidos: ['', Validators.required],
    celular: ['', Validators.required],
    correo: ['', [Validators.required, Validators.email]],
    ruc: [''],
    razonSocial: [''],
    region: ['', Validators.required],
    distrito: ['', Validators.required],
    direccion: ['', Validators.required],
    referencia: [''],
  });
  loading: boolean = false;
  producto = {price: 0, name: ''};
  submitSuccess: boolean = false;
  submitError: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private compraSrv: CompraService, 
    private authenticationService: AuthenticationService, private fb: FormBuilder, private router: Router) { 
    this.activatedRoute.params.subscribe(p => {
      this.idProduct = p['idproducto'];
      this.amountProduct = p['cantidad'];
    });
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
    this.getInfoFormulario();
  }

  ngOnInit(): void {
  }

  getInfoFormulario(): void {
    this.loadingData = true;
    const data = {
      idUser: this.currentUser.userId,
      idProduct: this.idProduct,
      quantity: this.amountProduct
    };
    this.compraSrv.getInfoOrden(data).subscribe(response => {
      console.log(response);
      if(response.data){
        let usuario = response.data.user[0];
        this.compraForm.get('nombres')?.setValue(usuario.name);
        this.compraForm.get('apellidos')?.setValue(usuario.lastName);
        this.compraForm.get('correo')?.setValue(usuario.email);
        if(response.data.product.length != 0){
          this.producto = response.data.product[0];
        }
      }
      this.loadingData = false;
    }, error => {
      this.router.navigate(["regalosCorporativos"]);
      this.loadingData = false;
    });
  }

  get getNombres() { return this.compraForm.get('nombres')?.invalid && this.compraForm.get('nombres')?.touched }
  get getApellidos() { return this.compraForm.get('apellidos')?.invalid && this.compraForm.get('apellidos')?.touched; }
  get getCelular() { return this.compraForm.get('celular')?.invalid && this.compraForm.get('celular')?.touched; }
  get getCorreo() { return this.compraForm.get('correo')?.invalid && this.compraForm.get('correo')?.touched; }
  get getRegion() { return this.compraForm.get('region')?.invalid && this.compraForm.get('region')?.touched; }
  get getDistrito() { return this.compraForm.get('distrito')?.invalid && this.compraForm.get('distrito')?.touched; }
  get getDireccion() { return this.compraForm.get('direccion')?.invalid && this.compraForm.get('direccion')?.touched; }

  onSubmitCompra() {
    this.submitError = false;
    if (this.compraForm.invalid) {
      return Object.values(this.compraForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(control => {
            control.markAsDirty();
            control.markAsTouched();
          });
        } else {
          control.markAsDirty();
          control.markAsTouched();
        }
      });
    }
    this.loading = true;
    const dataOrden = {
      idproduct: this.idProduct
      ,userName: this.compraForm.value.nombres
      ,userLastName: this.compraForm.value.apellidos
      ,userPhone: this.compraForm.value.celular
      ,userMail: this.compraForm.value.correo
      ,userRuc: this.compraForm.value.ruc
      ,userCompany: this.compraForm.value.razonSocial
      ,userProvince: this.compraForm.value.region
      ,userDistrict: this.compraForm.value.distrito
      ,userAddress: this.compraForm.value.direccion
      ,userReference: this.compraForm.value.referencia
      ,quantity: this.amountProduct
      ,price: this.producto.price
    };
    this.compraSrv.sendOrden(dataOrden).subscribe(response => {
      this.submitSuccess = true;
      setTimeout(() => {
        this.router.navigate(['endOrder']);
      }, 1000);
    }, error => {
      this.submitError = true;
      this.loading = false;
    });
  }
}

import { PrivacyPoliciesComponent } from './privacy-policies/privacy-policies.component';
import { StoreInfoComponent } from './store-info/store-info.component';
import { ProyectHorecaComponent } from './proyect-horeca/proyect-horeca.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { LicensesComponent } from './licenses/licenses.component';
import { ComplaintsBookComponent } from './complaints-book/complaints-book.component';
import { AuthCustomerGuard } from './../helpers/auth-customer.guard';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CorporativeGiftsComponent } from './corporative-gifts/corporative-gifts.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { BlogComponent } from './blog/blog.component';
import { CraftsmenDetailComponent } from './craftsmen-detail/craftsmen-detail.component';
import { CraftsmenComponent } from './craftsmen/craftsmen.component';
import { ContactComponent } from './contact/contact.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { QuestionsComponent } from './questions/questions.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { HomeComponent } from './home/home.component';
import { Routes } from "@angular/router";
import { DeliveryTimesComponent } from './delivery-times/delivery-times.component';
import { ExchangeReturnsComponent } from './exchange-returns/exchange-returns.component';
import { PurchaseProcess1Component } from './purchase-process1/purchase-process1.component';
import { PurchaseProcess3Component } from './purchase-process3/purchase-process3.component';

export const PortalRoutes: Routes = [
    {
        path: 'home',
        component: HomeComponent
    },
    {
        path: 'nosotros',
        component: AboutUsComponent
    },
    {
        path: 'preguntas-frecuentes',
        component: QuestionsComponent
    },
    {
        path: 'politicas-privacidad',
        component: PrivacyPoliciesComponent
    },
    {
        path: 'contactenos',
        component: ContactComponent
    },
    {
        path: 'terminos-condicionesRC',
        component: TermsConditionsComponent
    },
    {
        path: 'cambios-devoluciones',
        component: ExchangeReturnsComponent
    },
    {
        path: 'artesanos',
        component: CraftsmenComponent
    },
    {
        path: 'artesanos/:id',
        component: CraftsmenDetailComponent
    },
    {
        path: 'blog',
        component: BlogComponent
    },
    {
        path: 'blog/:id',
        component: BlogDetailComponent
    },
    {
        path: 'regalosCorporativos',
        component: CorporativeGiftsComponent
    },
    {
        path: 'regalosCorporativos/:id',
        component: ProductDetailComponent
    },
    {
        path: 'generateOrder/:idproducto/:cantidad',
        component: PurchaseProcess1Component,
        canActivate: [AuthCustomerGuard]
    },
    {
        path: 'endOrder',
        component: PurchaseProcess3Component
    },
    {
        path: 'libro-reclamaciones',
        component: ComplaintsBookComponent
    },
    {
        path: 'licencias',
        component: LicensesComponent
    },
    {
        path: 'carrito',
        component: ShoppingCartComponent
    },
    {
        path: 'proyectos',
        component: ProyectHorecaComponent
    },
    {
        path: 'tiendas',
        component: StoreInfoComponent
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
]
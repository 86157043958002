<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5"> <img src="assets/images/users/user.png" alt="user" class="profile-pic"> </button>
<mat-menu #profile="matMenu" class="mymegamenu">
    <div *ngIf="currentUser.typeUser == 'admin'">
        <button mat-menu-item disabled>Piska - Administrador</button>
        <button mat-menu-item [routerLink]="['/admin/product']">
            <mat-icon>dashboard</mat-icon> Productos </button>
        <button mat-menu-item [routerLink]="['/admin/orders']">
            <mat-icon>shopping_cart</mat-icon> Compras y pedidos </button>
        <button mat-menu-item [routerLink]="['/admin/packing']">
            <mat-icon>inventory_2</mat-icon> Empaques </button>
        <button mat-menu-item [routerLink]="['/admin/messages']">
            <mat-icon>email</mat-icon> Mensajes </button>
    </div>
    <div *ngIf="currentUser.typeUser == 'customer'">
        <!-- <button mat-menu-item disabled>{{currentUser.personName}}</button>
        <button mat-menu-item>
            <mat-icon>dashboard</mat-icon> Mis datos </button>
        <button mat-menu-item>
            <mat-icon>shopping_cart</mat-icon> Mis pedidos </button>
        <button mat-menu-item>
            <mat-icon>email</mat-icon> Mensajes </button> -->
    </div>
    <button mat-menu-item (click)="logoutHeader()">
        <mat-icon>exit_to_app</mat-icon> Cerrar sesión </button>
</mat-menu>
<div class="section_general">
    <!-- BANNER TIENDA -->
    <div class="banner banner_tienda">
        <h2>Tiendas</h2>
    </div>

    <div class="col-12 col-md-11 mx-auto px-3">
        <div class="tienda1">
            <h2 class="text-dorado">Puedes conseguir nuestros productos en:</h2>
        </div>
        <div class="tienda2">
            <h2 class="">Tiendas online en Perú</h2>
            <p>Haz clic en el logo para ser redireccionado al catálogo online.</p>
            <div class="pt-3">
                <a href="https://www.linio.com.pe/b/piska" target="_blank"><img src="assets/images/stores/Linio.png" alt="Linio" class="img-fluid"></a>
                <a href="https://piska.juntoz.com" target="_blank"><img src="assets/images/stores/Juntoz.png" alt="Juntoz" class="img-fluid"></a>
                <a href="https://www.lumingo.com/categoria/marcas/piska/c/piska" target="_blank"><img src="assets/images/stores/Lumingo.png" alt="Lumingo" class="img-fluid"></a>
                <a href="https://tiendacrema.pe" target="_blank"><img src="assets/images/stores/TiendaCrema.png" alt="Tienda Crema" class="img-fluid"></a>
            </div>
        </div>
        <div class="tienda2">
            <h2 class="">Tiendas online en el extranjero</h2>
            <p>Para las compras a nivel mundial nos encuentran bajo el nombre de nuestra marca KUSKA.</p>
            <div class="pt-3">
                <a href="javascript::void(0)"><img src="assets/images/stores/Kuska.png" alt="Kuska" class="img-fluid"></a>
                <a href="https://www.etsy.com/shop/KuskaPeru" target="_blank"><img src="assets/images/stores/Etsy.png" alt="Etsy" class="img-fluid"></a>
                <a href="https://www.amazon.com/s?me=A382SSFL1PS2R6&marketplaceID=ATVPDKIKX0DER" target="_blank"><img src="assets/images/stores/Amazon.png" alt="Amazon" class="img-fluid"></a>
                <a href="https://www.ebay.com/sch/kuskaperu/m.html" target="_blank"><img src="assets/images/stores/Ebay.png" alt="Ebay" class="img-fluid"></a>
            </div>
        </div>
        <div class="tienda2 tienda_otros">
            <h2 class="">Otros puntos de venta en Perú</h2>
            <p>Las siguientes tiendas comercializan oficialmente nuestros productos. Haz clic para ver más información.</p>
            <div class="pt-3">
                <button *ngFor="let tienda of listTiendas" (click)="openDialogTienda(tienda)">
                    <img [src]="tienda.imagen" class="img-fluid">
                </button>
            </div>
            <br><br>
        </div>
    </div>
</div>
<div class="section_general">
    <!-- BANNER PREGUNTAS -->
    <div class="banner banner_delivery">
        <h2>Términos y Condiciones RC</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-8 px-3 mx-auto tiempos-content">
        <!-- TIEMPOS 1 -->
        <div class="tiempos1">
            <h2><img src="assets/images/times/camion.png" class="img-fluid me-md-2"> Tiempos del despacho</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error unde quas beatae dolores. Ea, nulla voluptas? Incidunt assumenda, placeat exercitationem nemo, odit iste nam dignissimos optio dolorem temporibus inventore animi? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error unde quas beatae dolores. Ea, nulla voluptas? Incidunt assumenda, placeat exercitationem nemo, odit iste nam dignissimos optio dolorem temporibus inventore animi?</p>
        </div>

        <!-- TIEMPOS 2 -->
        <div class="tiempos2">
            <div class="row mx-auto align-items-center">
                <div class="col-12 col-md-5 col-lg-5 text-center tiempo2-img order-1 order-md-0">
                    <img src="assets/images/times/moto.png" alt="Icono motorizado" class="img-fluid">
                </div>
                <div class="col-12 col-md-7 col-lg-6 tiempos2-item order-0 order-md-1">
                    <h2>Por la mañana</h2>
                    <div class="row mx-0">
                        <div class="col-auto px-0"><img src="assets/images/times/piska.png" class="img-fluid"></div>
                        <div class="col"><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p></div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto px-0"><img src="assets/images/times/piska.png" class="img-fluid"></div>
                        <div class="col"><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p></div>
                    </div>
                </div>
            </div>
            <div class="row mx-auto align-items-center">
                <div class="col-12 col-md-6 col-lg-5 text-center tiempo2-img order-1 order-md-0">
                    <img src="assets/images/times/moto.png" alt="Icono motorizado" class="img-fluid">
                </div>
                <div class="col-12 col-md-6 col-lg-6 tiempos2-item order-0 order-md-1">
                    <h2>Por la tarde</h2>
                    <div class="row mx-0">
                        <div class="col-auto px-0"><img src="assets/images/times/piska.png" class="img-fluid"></div>
                        <div class="col"><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p></div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto px-0"><img src="assets/images/times/piska.png" class="img-fluid"></div>
                        <div class="col"><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p></div>
                    </div>
                </div>
            </div>
            <div class="row mx-auto align-items-center">
                <div class="col-12 col-md-6 col-lg-5 text-center tiempo2-img order-1 order-md-0">
                    <img src="assets/images/times/moto.png" alt="Icono motorizado" class="img-fluid">
                </div>
                <div class="col-12 col-md-6 col-lg-6 tiempos2-item order-0 order-md-1">
                    <h2>Por la noche</h2>
                    <div class="row mx-0">
                        <div class="col-auto px-0"><img src="assets/images/times/piska.png" class="img-fluid"></div>
                        <div class="col"><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p></div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto px-0"><img src="assets/images/times/piska.png" class="img-fluid"></div>
                        <div class="col"><p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
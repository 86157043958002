<div class="section_general">
    <!-- BANNER ARTESANOS -->
    <div class="banner banner_artesanos">
        <h2><span>Artesanos</span></h2>
    </div>

    <div class="content-artesanos">
        <!-- ARTESANOS 1 -->
        <div class="artesano1">
            <div class="row mx-0">
                <div class="col-md-6 ps-md-0 px-0 pe-md-4 pe-xl-5">
                    <h3 class="color_dorado artesano_h3 mb-2">Impacto Social</h3>
                    <h2 class="artesano_h2">El impacto económico, social y cultural de la actividad artesanal en el Perú</h2>
                    <p class="artesano_p">El artesano es aquella persona que ejerce una actividad profesional creativa en un nivel preponderantemente manual y conforme a sus conocimientos y habilidades técnicas y artísticas, que trabaja de forma autónoma y se mantiene de dicho trabajo.</p>
                    <p class="artesano_p">En PISKA valoramos la importancia no solo económica sino también social y cultural de la actividad artesanal en el Perú. Buscamos difundir el legado cultural heredado por todos los peruanos, a través de proyectos innovadores para el mercado peruano.</p>
                    <p class="artesano_p">Trabajamos con más de 30 talleres artesanales en todo el Perú, que producen productos con un fuerte contenido cultural, y que nos permiten no solo aportar en el desarrollo de la industria artesanal peruana, sino también de contribuir en la continuidad y perfeccionamiento de técnicas de producción artesanales y ancestrales, y sobre todo, de enriquecer y difundir el Patrimonio Cultural del Perú.</p>
                </div>
                <div class="col-md-6 artesano1_img">

                </div>
            </div>
        </div>

        <!-- ARTESANOS 2 -->
        <div class="artesano2">
            <h2 class="artesano_h2">Nuestro Equipo</h2>
            <p class="artesano_p mb-4 mb-md-5">Conoce más cerca de nuestros artesanos.</p>
            <div class="row">
                <div class="col-md-4 col-lg-3 mb-5" *ngFor="let item of [1,2,3,4]">
                    <a class="d-block" href="/artesanos/1">
                        <div class="img_artesano" [style.backgroundImage]="'url(../../../../../assets/images/craftsmen/artesano01.jpg)'"></div>
                        <div class="text_artesano">
                            <h2 class="text-center">Angel Román</h2>
                            <p class="mb-0 text-center">CERAMISTA</p>
                        </div>
                    </a>
                </div>
                <div class="col-md-4 col-lg-3 mb-5" *ngFor="let item of [1,2,3,4]">
                    <a class="d-block" href="/artesanos/1">
                        <div class="img_artesano" [style.backgroundImage]="'url(../../../../../assets/images/craftsmen/artesano02.jpg)'"></div>
                        <div class="text_artesano">
                            <h2 class="text-center">Julio Martinez</h2>
                            <p class="mb-0 text-center">JOYERO</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="section_general">
    <!-- BANNER TERMINOS Y CONDICIONES -->
    <div class="banner banner_reclamaciones">
        <h2>Libro de reclamaciones</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-9 col-xxl-8 mx-auto">
        <div class="d-flex justify-content-center align-items-center py-5 libroR1">
            <img src="assets/images/complaintsBook/piska-iso.png" alt="Piska" width="60" class="img-fluid d-block">
            <h2 class="text-dorado my-0 font-bolder ms-4">¿Quieres presentar tu reclamo o requerimiento por correo electrónico?<br>Lee los siguientes pasos:</h2>
        </div>
        <mat-divider class="mat-divider-custom"></mat-divider>
        <div class="libroR2">
            <ul class="mb-5">
                <li>
                    <span class="me-3">Descarga el archivo excel</span>
                    <a href="javascript::void(0)" class="btn-dorado-p btn-dowload"><img src="assets/images/complaintsBook/download.png"> Libro de reclamaciones</a>
                </li>
                <li><span>Guarda el archivo descargado en tu computadora.</span></li>
                <li><span>Completa todos los campos obligatorios del formulario.</span></li>
                <li><span>Una vez finalizado, guarda el archivo y envíanos tu reclamo a nuestro correo:<br><span class="text-dorado">piska@piska.pe</span>. Al momento de adjuntar el archivo excel, deberás colocar <span class="text-dorado">"RECLAMO"</span> o <span class="text-dorado">"REQUERIMIENTO"</span> (Depende de tu situación) - <span class="text-dorado">NOMBRES Y APELLIDOS.</span></span></li>
                <li><span>De conformidad y en cumplimiento del <span class="text-dorado">D.S. 011-2011 PCM,</span> el plazo de atención del reclamo es de <span class="text-dorado">30 días</span> calendario desde su presentación, el cual podrá extenderse excepcionalmente de acuerdo a la complejidad del requerimiento.</span></li>
                <li><span>Nos comunicaremos contigo para confirmar la recepción de tu reclamo.</span></li>
            </ul>

            <div class="card">
                <div class="card-body">
                    <h2 class="text-dorado font-bolder"><img src="assets/images/complaintsBook/importante.png">Importante</h2>
                    <p>R. Berrocal SAC, con RUC 20108568845, propietario de la marca PISKA - lo nuevo de lo nuestro, con domicilio Calle San Lucas 109, Pueblo Libre, Lima 15084 - Perú.</p>
                    <p><span class="text-dorado font-bold">RECLAMO:</span> Disconformidad relacionada a los productos o servicios.</p>
                    <p><span class="text-dorado font-bold">QUEJA:</span> Disconformidad no relacionada a los productos o servicios o malestar o descontento respecto a la atención a los clientes.</p>
                    <p class="mb-0">La formulación del reclamo no impide acudir a otras vías de solución de controversias ni es requisito previo para interponer una denuncia ante el INDECOPI.</p>
                </div>
            </div>
        </div>
    </div>
</div>
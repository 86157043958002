<div class="marcasc">
    <div class="row align-items-center">
        <div class="col-12 mb-4 mb-md-0 marcasc-1 col-md-6">
            <h2 class="my-0 marcasc-title text-center text-md-start">Marcas que depositaron<br>su confianza en nosotros</h2>
        </div>
        <div class="col-12 col-md-6 px-5">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                    <img src="{{ slide.img }}" alt="" width="100%">
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</div>
<div class="section_general">
    <!-- BANNER CAMBIOS Y DEVOLUCIONES -->
    <div class="banner banner_cambios">
        <h2>Cambios y devoluciones</h2>
    </div>

    <div class="col-12 col-md-11 col-lg-10 col-xl-9 mx-auto cambios-content">
        <div class="cambiosMensaje row mx-0">
            <div class="col-12 col-md-auto p-4 order-1 order-md-0 text-center">
                <img src="assets/images/exchange/icon.png" class="img-fluid me-md-2">
            </div>
            <div class="col-12 col-md order-0 order-md-1 py-3">
                <p class="mb-0">Si por alguna razón no has quedado satisfecho(a) con tu compra, por favor comunícate con nosotros al 996 651 734 o al 953 532 578, envíanos un email a berrocal.rd@piska.pe / rubenberrocal@r-berrocal.com para poder revisar tu caso.</p>
            </div>
        </div>

        <!-- CAMBIOS 1 -->
        <div class="cambios1">
            <h2><img src="assets/images/exchange/icon-2.png" class="img-fluid me-2 me-md-3">Para cancelar un pedido</h2>
            <p>Sólo se pueden cancelar pedidos, si y solo si no se ha empezado a producir el pedido (esto incluye la compra de materia prima).</p>
        </div>
        <div class="cambios1">
            <h2><img src="assets/images/exchange/icon-2.png" class="img-fluid me-2 me-md-3">Cambios en un pedido</h2>
            <p>Se aceptarán cambios en un pedido, si y solo si no se ha comenzado a producir el producto que se desea hacer cambios y/o ajustes en sus especificaciones.</p>
            <!-- <div class="row mx-0 ms-4 ms-xl-5">
                <div class="col-12">
                    <div class="row mx-0 mb-3 cambios-item">
                        <div class="col-auto px-0">
                            <img src="assets/images/times/piska.png" class="img-fluid">
                        </div>
                        <div class="col">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto px-0">
                            <img src="assets/images/times/piska.png" class="img-fluid">
                        </div>
                        <div class="col">
                            <p class="mb-0">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- <div class="cambios1">
            <h2><img src="assets/images/exchange/icon-2.png" class="img-fluid me-2 me-md-3">Requisitos</h2>
            <div class="row mx-0 ms-4 ms-xl-5">
                <div class="col-12 col-md-6 pe-xl-5">
                    <div class="row mx-0 mb-3 cambios-item">
                        <div class="col-auto px-0">
                            <img src="assets/images/times/piska.png" class="img-fluid">
                        </div>
                        <div class="col">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3 mb-md-0">
                        <div class="col-auto px-0">
                            <img src="assets/images/times/piska.png" class="img-fluid">
                        </div>
                        <div class="col">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 ps-xl-5">
                    <div class="row mx-0 mb-3 cambios-item">
                        <div class="col-auto px-0">
                            <img src="assets/images/times/piska.png" class="img-fluid">
                        </div>
                        <div class="col">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto px-0">
                            <img src="assets/images/times/piska.png" class="img-fluid">
                        </div>
                        <div class="col">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ipsam quas deleniti fugit eaque, sunt quis.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="cambios1">
            <h2><img src="assets/images/exchange/icon-2.png" class="img-fluid me-2 me-md-3">Devoluciones</h2>
            <p>En caso el cliente detecte productos defectuosos, PISKA repondrá o reparará el producto, o en todo caso hará el reembolso respectivo al cliente, previo análisis del caso.</p>
        </div>
    </div>
</div>
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductoCatalogo } from 'src/app/models/ProductoCatalogo';
import { Usuario } from 'src/app/models/Usuario';
import { CatalogoService } from 'src/app/services/portal/catalogo.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';

export interface FeatureCatalogue{
  id?: number;
  question?: string;
  answer?: string;
}
export interface ImgCatalogue{
  idImage?: number;
  imageName?: string;
  imageType?: string;
}
export interface StockInfo{
  minQuantity?: number;
  maxQuantity?: number;
  amount?: number;
}

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {

  amountProduct: number = 0;
  idProduct: number = 0;
  listImagenes: ImgCatalogue[] = [];
  detalleProducto: ProductoCatalogo = {};
  listPrecios: StockInfo[] = [];
  stockInfo: StockInfo = {
    minQuantity: 0,
    maxQuantity: 0,
    amount: 0
  };
  mingQuantityText: number = 0;
  listCaracteristicas: FeatureCatalogue[] = [];
  linkImgPrincipal: string = '';
  activeImg: number = 0;
  listRelacionados: ProductoCatalogo[] = [];
  loadingData: boolean = false;
  currentUser: Usuario = {};
  
  constructor(private catalogoSrv: CatalogoService, private activatedRoute: ActivatedRoute, public dialog: MatDialog,
    private authenticationService: AuthenticationService, private router: Router) { 
    this.activatedRoute.params.subscribe(p => {
      this.idProduct = p['id'];
    });
    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });
    this.getDetailProduct();
  }

  ngOnInit(): void {
    console.log(this.currentUser);
    this.getProductosRelacionados();
  }

  getDetailProduct(): void {
    this.loadingData = true;
    this.catalogoSrv.getDetalleProducto(this.idProduct).subscribe(response => {
      this.listImagenes = response.data.image;
      this.linkImgPrincipal = this.listImagenes[0].imageName!;
      this.detalleProducto = response.data.detail[0];
      this.listPrecios = response.data.price;
      
      if(this.listPrecios.length != 0){
        this.stockInfo.minQuantity = this.listPrecios[0].minQuantity || 0;
        this.mingQuantityText = this.stockInfo.minQuantity;
        this.amountProduct = this.listPrecios[0].minQuantity || 0;
        this.stockInfo.amount = this.listPrecios[0].amount || 0;
        this.listPrecios.forEach(item => {
          this.stockInfo.maxQuantity = item.maxQuantity || 1;
        });
      }

      this.listCaracteristicas = response.data.features;
      this.loadingData = false;
    }, error => {
      this.loadingData = false;
    });
  }

  reduceAmount(): void {
    if(this.amountProduct != 0 && this.amountProduct > this.stockInfo.minQuantity!){
      this.amountProduct--;
      this.calcularPrecio(this.amountProduct);
    }
  }

  increaseAmount(): void {
    if(this.amountProduct < this.stockInfo.maxQuantity!){
      this.amountProduct++;
      this.calcularPrecio(this.amountProduct);
    }
  }

  calcularPrecio(cantidad: number): void {
    const array = this.listPrecios.filter(item => item.minQuantity! <= cantidad && item.maxQuantity! >= cantidad);
    if(array.length != 0){
      this.mingQuantityText = array[0].minQuantity || 0;
      this.stockInfo.amount = array[0].amount;
    }
  }

  changeImg(index: number): void {
    this.activeImg = index;
    this.linkImgPrincipal = this.listImagenes[index].imageName!;
  }

  getProductosRelacionados(): void {
    const params = {
      idProduct: this.idProduct,
      idCategory: "2"
    };
    this.catalogoSrv.getProductosRelacionados(params).subscribe(response => {
      this.listRelacionados = response.data;
    });
  }

  openDialogPrecios(): void {
    const dialogRef = this.dialog.open(DialogPreciosProducto, {
      width: '500px',
      panelClass: 'fondo_dialog',
      data: this.listPrecios
    });
  }

  agregarAlCarrito(): void {
    if(this.currentUser.typeUser == 'customer'){
      this.router.navigate([`generateOrder/${this.idProduct}/${this.amountProduct}`])
    }else{
      this.authenticationService.triggerReqLogin(true);
    }
  }
}

@Component({
  selector: 'dialog-precios-producto',
  templateUrl: 'modal-precios.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class DialogPreciosProducto {

  constructor(public dialogRef: MatDialogRef<DialogPreciosProducto>,
    @Inject(MAT_DIALOG_DATA) public listPrecios: StockInfo[]) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

}
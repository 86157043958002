import { CompraService } from './../../services/portal/compra.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm = this.fb.group({
    nombreApellido: ['', Validators.required],
    celular: ['', Validators.required],
    correo: ['', [Validators.required, Validators.email]],
    empresa: ['', Validators.required],
    paginaWeb: ['', Validators.required],
    direccion: ['', Validators.required],
    pais: ['', Validators.required],
    mensaje: ['', Validators.required],
  });
  loading: boolean = false;

  constructor(private fb: FormBuilder, private compraSrv: CompraService) { }

  ngOnInit(): void {
  }

  get getNombreApellido() { return this.contactForm.get('nombreApellido')?.invalid && this.contactForm.get('nombreApellido')?.touched }
  get getCelular() { return this.contactForm.get('celular')?.invalid && this.contactForm.get('celular')?.touched; }
  get getCorreo() { return this.contactForm.get('correo')?.invalid && this.contactForm.get('correo')?.touched; }
  get getEmpresa() { return this.contactForm.get('empresa')?.invalid && this.contactForm.get('empresa')?.touched; }
  get getPaginaWeb() { return this.contactForm.get('paginaWeb')?.invalid && this.contactForm.get('paginaWeb')?.touched; }
  get getDireccion() { return this.contactForm.get('direccion')?.invalid && this.contactForm.get('direccion')?.touched; }
  get getPais() { return this.contactForm.get('pais')?.invalid && this.contactForm.get('pais')?.touched; }
  get getMensaje() { return this.contactForm.get('mensaje')?.invalid && this.contactForm.get('mensaje')?.touched; }


  onSubmit() {
    if (this.contactForm.invalid) {
      return Object.values(this.contactForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(control => {
            control.markAsDirty();
            control.markAsTouched();
          });
        } else {
          control.markAsDirty();
          control.markAsTouched();
        }
      });
    }
    this.loading = true;
    const dataOrden = {
      name: this.contactForm.value.nombreApellido
      ,phone: this.contactForm.value.celular
      ,email: this.contactForm.value.correo
      ,company: this.contactForm.value.empresa
      ,web: this.contactForm.value.paginaWeb
      ,address: this.contactForm.value.direccion
      ,country: this.contactForm.value.pais
      ,content: this.contactForm.value.mensaje
    };
    this.compraSrv.enviarFormulario(dataOrden).subscribe(response => {
      console.log(response);
      Swal.fire({
        text: 'Se envió los datos satisfactoriamente.',
        icon: 'success'
      });
    }, error => {
      this.loading = false;
      Swal.fire({
        text: 'Hubo un error al enviar los datos.',
        icon: 'error'
      });
    });
  }

}
